export const storeInfoNm = 'danbamStoreInfo';

export const authTokenNm = 'danbamAuthTokenU';
export const refreshTokenNm = 'danbamRefreshTokenU';


const apiVersion = '/v1'

export const apiLocalFrontUrl = 'http://localhost:8081' + apiVersion;
export const apiDevFrontUrl = 'http://13.125.233.40:8080'+ apiVersion;
export const apiRealFrontUrl = 'https://danbam.com'+ apiVersion;

export const storeImgUploadUrl = 'uploads/store';
export const menuImgUploadUrl = 'uploads/menu';

//BE Url api
export const imgUpload = "/api/shared/imgUpload";
export const currencyList = "/api/shared/currencyList";

export const storeGateWayInfo = "/api/store/gateway";
export const categoryList = "/api/category";
export const menuList = "/api/menu";
export const storeInfo = "/api/store";
