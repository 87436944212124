import React, {Suspense, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import danbamGateway from "images/danbamGateway.png";
import {AxiosRequest} from "../variables/customAxios";
import {storeGateWayInfo} from "../constants";
import {setStoreInfo} from "../variables/localStorage";
import Swal from "sweetalert2";

function StoreGateWay() {
    const [dots, setDots] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [store, setStore] = useState(null);
    const {request} = AxiosRequest();
    const fetchStoreInfo = async (storeId) => {
        if (storeId !== null) {
            try {
                await request(storeGateWayInfo + `/${storeId}`, {
                    method: 'GET'
                }).then(response => {
                    if (response.responseCode === 0) {
                        setStoreInfo(response.result);
                        setTimeout(() => {
                            navigate('/storeMenu')
                        }, 0);
                    } else {
                        Swal.fire({
                            title: 'Oops!',
                            html: response.message,
                            icon: 'error',
                            confirmButtonText: 'OK',
                            showCancelButton: false, // 취소 버튼 비활성화
                            allowOutsideClick: false, // 외부 클릭 허용하지 않음
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // SweetAlert 확인 후 다른 페이지로 이동
                                navigate('/login'); // 이동할 페이지 경로
                            }
                        });
                    }
                });
            } catch (error) {
                Swal.fire({
                    title: 'Oops!',
                    html: error,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    showCancelButton: false, // 취소 버튼 비활성화
                    allowOutsideClick: false, // 외부 클릭 허용하지 않음
                }).then((result) => {
                    if (result.isConfirmed) {
                        // SweetAlert 확인 후 다른 페이지로 이동
                        navigate('/login'); // 이동할 페이지 경로
                    }
                });
            }
        }
    };


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const storeId = urlParams.get('storeId');
        if (storeId) {
            fetchStoreInfo(storeId);
        }
        const interval = setInterval(() => {
            setDots(prevDots => {
                if (prevDots.length < 5) {
                    return prevDots + '.';
                } else {
                    return '';
                }
            });
        }, 300); // 0.5초마다 점을 추가

        return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
    }, []);

    return (
        <div className="vh-100">
            <Suspense fallback={<div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div>
            }>
                <div className="authincation p-meddle">
                    <div className="container ">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-md-6">
                                <div className="authincation-content">
                                    <div className="row no-gutters">
                                        <div className="col-xl-12">
                                            <div className="auth-form">
                                                <div className="text-center mb-3">
                                                    <img alt=""/>
                                                </div>
                                                <h4 className="text-center mb-4 "><img src={danbamGateway} alt=""/></h4>
                                                <div className="new-account mt-3">
                                                    <p className="text-center">
                                                        Navigating to the page{dots}
                                                    </p>
                                                    <p className="text-center">
                                                        이동중{dots}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
}

export default StoreGateWay;

