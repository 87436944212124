import axios from 'axios';
import {useState} from 'react';
import {getAuthToken} from 'variables/localStorage';
import {apiDevFrontUrl, apiLocalFrontUrl, apiRealFrontUrl} from 'constants.js'

function getAPIURL() {
    switch (process.env.REACT_APP_VER) {
        case 'development':
            return apiDevFrontUrl
        case 'production':
            return apiRealFrontUrl
        default:
            return apiLocalFrontUrl
    }
}

export function AxiosRequest() {
    const [loading, setLoading] = useState(false);
    const [axioCnt, setaxioCnt] = useState(0);

    function request(url, config = {}) {
        const authToken = getAuthToken();
        url = url.startsWith('/') ? getAPIURL() + url : url;
        return axios.request({
            url,
            ...config,
            headers: {
                'Authorization': `Bearer ${authToken}` // 인증 헤더 추가
            },
        }).then(e => {
            return e.data
        });
    }

    function requestWithLoading(url, config = {}) {
        const authToken = getAuthToken();
        url = url.startsWith('/') ? getAPIURL() + url : url;
        setLoading(true);
        setaxioCnt(axioCnt + 1);
        return axios.request({
            url,
            ...config,
            headers: {
                'Authorization': `Bearer ${authToken}` // 인증 헤더 추가
            },
        }).then(e => {
            return e.data
        }).finally(() => {
            setaxioCnt(axioCnt - 1);
            console.log(`통신 종료 : ${axioCnt}`)
            if (axioCnt <= 0) {
                setaxioCnt(0);
                setLoading(false);
            }
        });
    }

    return {request, requestWithLoading, loading};
}
