import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import prepared from 'images/prepared.png';

const MenuGridList = ({c, m, currency}) => {
    const [category, setCategory] = useState([]);
    const [menu, setMenu] = useState([]);
    useEffect(() => {
        setCategory(c)
        setMenu(m)
    }, [c, m]);


    {/*안씀
    function handleClick(type, id) {
        let temp = dataheart.map((data) => {
            if (id === data.id) {
                if (type === "heart") {
                    return {...data, heart: !data.heart};
                } else if (type === "check") {
                    return {...data, check: !data.check};
                }
            }
            return data;
        });
        setDataheart(temp);
    }
*/
    }
    return (
        <div className="row">
            {category.map((category, i) => (
                <React.Fragment key={category.categoryId}>
                    <h4 className="mb-2 cate-title" name={`category_${category.categoryId}`}>
                        {category.name}
                    </h4>
                    {menu.map((menu, j) => (
                        menu.categoryId === category.categoryId ? (
                            <div className="col-xl-3 col-xxl-4 col-sm-6" key={menu.menuId}>
                                <div className="card dishe-bx b-hover style-1">
                                    {/*하트 기능 [S]
                                    <i className={`fa-solid fa-heart ms-auto c-heart c-pointer ${item.heart ? "active" : ""}`}
                                       onClick={() => handleClick('heart', item.id)}
                                    ></i>
                                    하트 기능 [E]*/}
                                    <div className="card-body pb-0 pt-3">
                                        <div className="text-center mb-2">
                                            <img
                                                src={menu.file.length === 0 ? prepared : menu.file[0]}
                                                alt=""
                                                style={{height: "17rem"}}
                                            />
                                        </div>
                                        <div className="border-bottom pb-3">
                                            <h4 className="font-w500 mb-1">{menu.menuNm}</h4>

                                            <div className="d-flex align-items-center">
                                                {menu.menuInfo}
                                                {/*
                                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8 0.500031L9.79611 6.02789H15.6085L10.9062 9.4443L12.7023 14.9722L8 11.5558L3.29772 14.9722L5.09383 9.4443L0.391548 6.02789H6.20389L8 0.500031Z"
                                                        fill="#FC8019"/>
                                                </svg>
                                                <p className="font-w500 mb-0 px-2">5.0</p>
                                                <svg className="me-2" width="4" height="5" viewBox="0 0 4 5"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2" cy="2.50003" r="2" fill="#C4C4C4"/>
                                                </svg>
                                                <p className=" font-w500 mb-0">1k+ Reviews</p>
                                                <svg className="mx-2" width="4" height="5" viewBox="0 0 4 5"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2" cy="2.5" r="2" fill="#C4C4C4"/>
                                                </svg>
                                                <p className="font-w500 mb-0">2.97km</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-0 pt-2">
                                        <div className="common d-flex align-items-center justify-content-end">
                                            <div>
                                                <Link to={"#"}><h4>{menu.title}</h4></Link>
                                                {
                                                    currency.map((currency, i) => (
                                                        currency.code === menu.currency ? (
                                                            <h3 className=" mb-0 text-primary">{currency.symbol} {menu.price.toLocaleString()} {currency.name}</h3>
                                                        ) : null
                                                    ))
                                                }
                                            </div>
                                            {/*
                                            <div className={`plus c-pointer ${menu.check ? "active" : ""}`}
                                                /* onClick={() => handleClick('check', menu.id)}
                                            >
                                                <div className="sub-bx"></div>
                                            </div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null // 조건이 거짓일 때는 null을 반환
                    ))}
                </React.Fragment>
            ))}

            {/*
            {dataheart.map((item, ind) => (
                <div className="col-xl-3 col-xxl-4 col-sm-6" key={ind}>
                    <div className="card dishe-bx b-hover style-1">
                        {/*하트 기능 [S]
                        <i className={`fa-solid fa-heart ms-auto c-heart c-pointer ${item.heart ? "active" : ""}`}
                           onClick={() => handleClick('heart', item.id)}
                        ></i>
                        하트 기능 [E]
                        <div className="card-body pb-0 pt-3">
                            <div className="text-center mb-2">
                                <img src={item.image} alt=""/>
                            </div>
                            <div className="border-bottom pb-3">
                                <h4 className="font-w500 mb-1">Fast Food Resto</h4>
                                <div className="d-flex align-items-center">
                                    <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 0.500031L9.79611 6.02789H15.6085L10.9062 9.4443L12.7023 14.9722L8 11.5558L3.29772 14.9722L5.09383 9.4443L0.391548 6.02789H6.20389L8 0.500031Z"
                                            fill="#FC8019"/>
                                    </svg>
                                    <p className="font-w500 mb-0 px-2">5.0</p>
                                    <svg className="me-2" width="4" height="5" viewBox="0 0 4 5"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2" cy="2.50003" r="2" fill="#C4C4C4"/>
                                    </svg>
                                    <p className=" font-w500 mb-0">1k+ Reviews</p>
                                    <svg className="mx-2" width="4" height="5" viewBox="0 0 4 5"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2" cy="2.5" r="2" fill="#C4C4C4"/>
                                    </svg>
                                    <p className="font-w500 mb-0">2.97km</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer border-0 pt-2">
                            <div className="common d-flex align-items-center justify-content-between">
                                <div>
                                    <Link to={"#"}><h4>{item.title}</h4></Link>
                                    <h3 className=" mb-0 text-primary">$5.59</h3>
                                </div>
                                <div className={`plus c-pointer ${item.check ? "active" : ""}`}
                                     onClick={() => handleClick('check', item.id)}
                                >
                                    <div className="sub-bx"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
             페이징 번호[S] 아마 앞으로 필요 없을 예정 */}
            {/*
                            <div className="d-flex align-items-center justify-content-xl-between justify-content-center flex-wrap pagination-bx">
								<div className="mb-sm-0 mb-3 pagination-title">
									<p className="mb-0"><span>Showing 1-5</span> from <span>100</span> data</p>
								</div>
								<nav>
									<ul className="pagination pagination-gutter">
										<li className="page-item page-indicator">
											<Link to={"#"} className="page-link" >
												<i className="la la-angle-left"></i>
                                            </Link>
										</li>
										<li className="page-item active"><Link to={"#"} className="page-link" >1</Link>
										</li>
										<li className="page-item"><Link to={"#"} className="page-link" >2</Link></li>

										<li className="page-item"><Link to={"#"} className="page-link" >3</Link></li>
										<li className="page-item page-indicator">
											<Link to={"#"} className="page-link" >
												<i className="la la-angle-right"></i>
                                            </Link>
										</li>
									</ul>
								</nav>
							</div>*/}
            {/* 페이징 번호[E] */}
        </div>
    )
}

export default MenuGridList;