import React, {useContext} from "react";

/// React router dom
import {Outlet, Route, Routes} from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
//import Main from './layouts/Main';
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import MainMenu from "../view/menu/MainMenu";

//Restaurant
//Drivers
/////Demo
/// App
/// Product List
/// Charts
/// Bootstrap
/// Plugins
// Widget
/// Table
/// Form
/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import {ThemeContext} from "../context/ThemeContext";
import StoreGateWay from "../view/StoreGateWay";


const Markup = () => {

    const allroutes = [
        /// Dashboard
        //1차 오픈은 메뉴만 진행한다.
        {url: "storeMenu", component: <MainMenu/>}

        /*
      { url: "", component: <Home /> },
      { url: "dashboard", component: <Home /> },
      { url: "dashboard-dark", component: <DashboardDark /> },
      { url: "food-order", component: <FoodOrder /> },
      { url: "message", component: <Message /> },
      { url: "order-history", component: <OrderHistory /> },
      { url: "notification", component: <Notification /> },
      { url: "bill", component: <Bill /> },
      { url: "setting", component: <HomeSetting /> },
      { url: "checkout", component: <CheckoutPage /> },

      // Restaurant
      { url: "restaurant", component: <Restaurant /> },
      { url: "withdrow", component: <Withdrow /> },
      { url: "menu", component: <MainMenu /> },
      { url: "orders", component: <Orders /> },
      { url: "customer-reviews", component: <CustomerReviews /> },


      //Drivers
      {url:"deliver-main", component:<DeliverMain />},
      {url:"deliver-order", component:<DeliverOrder />},
      {url:"feedback", component:<Feedback />},

      /////Demo
      { url: "container-wide", component: <Theme1 /> },
      { url: "horizontal-sidebar", component: <Theme2 /> },
      { url: "nav-header", component: <Theme3 /> },
      { url: "secondary-sidebar", component: <Theme4 /> },
      { url: "mini-sidebar", component: <Theme5/> },
      { url: "sidebar-theme", component: <Theme6/> },
      { url: "header-theme", component: <Theme7/> },

      /// Apps
      { url: "app-profile", component: <AppProfile /> },
      { url: "email-compose", component: <Compose /> },
      { url: "email-inbox", component: <Inbox /> },
      { url: "email-read", component: <Read /> },
      { url: "app-calender", component: <Calendar /> },
      { url: "post-details", component: <PostDetails /> },

    /// Shop
      { url: "ecom-product-grid", component: <ProductGrid /> },
      { url: "ecom-product-list", component: <ProductList /> },
      { url: "ecom-product-detail", component: <ProductDetail /> },
      { url: "ecom-product-order", component: <ProductOrder /> },
      { url: "ecom-checkout", component: <Checkout /> },
      { url: "ecom-invoice", component: <Invoice /> },
      { url: "ecom-customers", component: <Customers /> },
  //
      ///// Chart
      { url: "chart-sparkline", component: <SparklineChart /> },
      { url: "chart-chartjs", component: <ChartJs /> },
      { url: "chart-apexchart", component: <ApexChart /> },
      { url: "chart-rechart", component: <RechartJs /> },
  //
      ///// Bootstrap
      { url: "ui-alert", component: <UiAlert /> },
      { url: "ui-badge", component: <UiBadge /> },
      { url: "ui-button", component: <UiButton /> },
      { url: "ui-modal", component: <UiModal /> },
      { url: "ui-button-group", component: <UiButtonGroup /> },
      { url: "ui-accordion", component: <UiAccordion /> },
      { url: "ui-list-group", component: <UiListGroup /> },
      { url: "ui-card", component: <UiCards />},
      { url: "ui-carousel", component: <UiCarousel /> },
      { url: "ui-dropdown", component: <UiDropDown /> },
      { url: "ui-popover", component: <UiPopOver /> },
      { url: "ui-progressbar", component: <UiProgressBar /> },
      { url: "ui-tab", component: <UiTab /> },
      { url: "ui-pagination", component: <UiPagination /> },
      { url: "ui-typography", component: <UiTypography /> },
      { url: "ui-grid", component: <UiGrid /> },
      ///// Plugin
      { url: "uc-select2", component: <Select2 /> },
      { url: "uc-sweetalert", component: <MainSweetAlert /> },
      { url: "uc-toastr", component: <Toastr /> },
      { url: "map-jqvmap", component: <JqvMap /> },
      { url: "uc-lightgallery", component: <Lightgallery /> },


      ///// Widget
      { url: "widget-basic", component: <Widget /> },

      ///// Form
      { url: "form-element", component: <Element /> },
      { url: "form-wizard", component: <Wizard /> },
      { url: "form-ckeditor", component: <CkEditor /> },
      { url: "form-pickers", component: <Pickers /> },
      { url: "form-validation", component: <FormValidation /> },
  //
      ///// table
        { url: 'table-filtering', component: <FilteringTable /> },
      { url: 'table-sorting', component: <SortingTable /> },
      { url: "table-bootstrap-basic", component: <BootstrapTable /> },*/

    ];

    return (
        <>
            <Routes>
                <Route exact path='storeGateWay' element={<StoreGateWay/>}/>
                <Route path='page-lock-screen' element={<LockScreen/>}/>
                <Route path='page-error-400' element={<Error400/>}/>
                <Route path='page-error-403' element={<Error403/>}/>
                <Route path='page-error-404' element={<Error404/>}/>
                <Route path='page-error-500' element={<Error500/>}/>
                <Route path='page-error-503' element={<Error503/>}/>
                <Route element={<MainLayout/>}>
                    {allroutes.map((data, i) => (
                        <Route
                            key={i}
                            exact
                            path={`${data.url}`}
                            element={data.component}
                        />
                    ))}
                </Route>
            </Routes>
            <ScrollToTop/>
        </>
    );
};


function MainLayout() {
    const {menuToggle} = useContext(ThemeContext);
    return (
        <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
            <Nav/>
            <div className="content-body " style={{minHeight: window.screen.height - 45 , marginLeft:0}}>
                <div className="container">
                    <Outlet/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Markup;
