// StoreAuthContext.js
import React, {createContext, useContext, useEffect} from 'react';
import {getStoreInfo} from "./localStorage";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

const StoreAuthContext = createContext();

export const StoreProvider = ({children}) => {
    const navigate = useNavigate();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const storeId = urlParams.get('storeId');
        if (getStoreInfo() === null && storeId === null) {
            Swal.fire({
                title: 'Oops!',
                html: '정상적인 경로로 이용해주세요.<br>QrData is not setting. Please use a normal route.',
                icon: 'error',
                confirmButtonText: 'OK',
                showCancelButton: false, // 취소 버튼 비활성화
                allowOutsideClick: false, // 외부 클릭 허용하지 않음
            }).then((result) => {
                if (result.isConfirmed) {
                    // SweetAlert 확인 후 다른 페이지로 이동
                    navigate('/'); // 이동할 페이지 경로
                }
            });
        }
    }, []);

    return (
        <StoreAuthContext.Provider value={null}>
            {children}
        </StoreAuthContext.Provider>);
};

export const useStore = () => {
    return useContext(StoreAuthContext);
};
