import {authTokenNm, refreshTokenNm, storeInfoNm} from 'constants.js'
//스토어 정보 조회 용[S] 
export const getStoreInfo = () => {
    return JSON.parse(localStorage.getItem(storeInfoNm));
};
export const setStoreInfo = (value) => {
    localStorage.setItem(storeInfoNm, JSON.stringify(value));
};
//스토어 정보 조회 용[E]

//범용적 겟셋[S]
export const setLocalStorage = (name, token, options = {}) => {
    localStorage.setItem(name, token);
};
export const getLocalStorage = (name) => {
    return localStorage.getItem(name);
};
//범용적 겟셋[E]

//추후 사용자 로그인 대비
export const getAuthToken = () => {
    return localStorage.getItem(authTokenNm);
};
export const setAuthToken = (token, options = {}) => {
    localStorage.setItem(authTokenNm, token);
}
export const removeAuthToken = () => {
    localStorage.removeItem(authTokenNm);
};

export const getRefreshToken = () => {
    return localStorage.getItem(refreshTokenNm);
};

export const setRefreshToken = (token, options = {}) => {
    localStorage.setItem(refreshTokenNm, token);
};

export const removeRefreshToken = () => {
    localStorage.removeItem(refreshTokenNm);
};