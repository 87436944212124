import React from 'react';
import {Swiper, SwiperSlide,} from "swiper/react";
import {Autoplay, Pagination} from "swiper";
import "swiper/css";

const BannerSlider = ({fileUrl}) => {
    return (
        fileUrl.length > 0 ? (
            <div className="position-relative">
                <div className="swiper-pagination-banner"></div>
                <Swiper className="mySwiper-1"
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            el: '.swiper-pagination-banner',
                            clickable: true
                        }}
                        autoplay={{
                            delay: 3000,
                        }}
                        modules={[Autoplay, Pagination]}
                >
                    {fileUrl.map((data, ind) => (
                        <SwiperSlide key={ind}>
                            <div className="banner-bx">
                                <img src={data} alt=""/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        ) : null
    );
}

export default BannerSlider;